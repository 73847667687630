/* src/App.css */
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.App-header nav ul {
  list-style-type: none;
  padding: 0;
}

.App-header nav ul li {
  display: inline;
  margin-right: 20px;
}

.App-header nav ul li a {
  color: white;
  text-decoration: none;
}

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
  margin: 20px auto;
  max-width: 800px;
}

.card h2 {
  color: #333;
}

.card p {
  color: #666;
}
